
  import info from "@/components/_universal/list-info.vue";
  import tablelist from "@/components/_universal/list-table.vue";
  import purchaseOrderReport from "./purchaseOrderReport.vue";

  // Standard Components
  import EVENTS from "@/constants/events";

  // Controller
  import PurchaseOrderController from "@/controllers/purchase-order-controller";
  import listMixin from "@/mixin/list-mixin";

  // Initialize the controller once for reuse.
  const purchaseOrderController = new PurchaseOrderController();

  export default {
    mixins: [listMixin],
    data() {
      return {
        listDataObj: {},
        EVENT_ACTION: {},
        project: {},
        timer: null,          // Holds the interval timer ID.
        apiCallCount: 0,      // Tracks the total number of API calls.
        unsubscribe: null,    // To store the Vuex subscription function.
      };
    },
    methods: {
      /**
       * onFilterChange: Retrieves updated list results using the current filter
       * and updates the Vuex store.
       */
      async onFilterChange() {
        // Get current filter from Vuex store, if available.
        if (this.$store.getters.getList.filter) {
          this.filter = this.$store.getters.getList.filter;
        }
        // Call the API using the controller.
        let data = await purchaseOrderController.getListResults(this.filter);
        // Update Vuex store with the retrieved results.
        await this.updateResults(data.resources);
      },
      /**
       * loadData: Loads the initial list and updates the Vuex store.
       */
      async loadData() {
        // Retrieve the list data from the controller.
        this.listDataObj = await purchaseOrderController.list();
        // If data is successfully retrieved, update the store.
        if (this.listDataObj.table.data.success) {
          this.updateResults(this.listDataObj.table.data.resources);
        }
      },
      /**
       * updateResults: Dispatches the new list data to the Vuex store.
       */
      async updateResults(results) {
        this.$store.dispatch("setList", results);
      },
      /**
       * subscribeOnFilterChange: Subscribes to Vuex mutations to re-trigger the API call
       * when the filter changes.
       */
      subscribeOnFilterChange() {
        this.unsubscribe = this.$store.subscribe((mutation, state) => {
          if (mutation.type === "setFilter") {
            this.onFilterChange();
          }
        });
      },
    },
    components: {
      info,
      tablelist,
      purchaseOrderReport,
    },
    async mounted() {
      // Optionally track events in production:
      // if (process.env.VUE_APP_ENVIRONMENT === "production") {
      //   window["analytics"].page(this.EVENT_ACTION.PURCHASEORDER.View);
      // }
      // Subscribe to filter changes.
      this.subscribeOnFilterChange();

      // Set up an interval to call onFilterChange every 20 seconds.
      // Stop after 30 API calls.
      this.timer = setInterval(async () => {
        if (this.apiCallCount >= 30) {
          clearInterval(this.timer);
          this.timer = null;
          
          return;
        }
        await this.onFilterChange();
        this.apiCallCount++;
        
      }, 20000);
    },
    async created() {
      // Import event constants.
      this.EVENT_ACTION = EVENTS;
      // Load the initial data.
      await this.loadData();
    },
    beforeDestroy() {
      // Reset the store data.
      this.updateResults(null);
      // Unsubscribe from Vuex store events.
      if (this.unsubscribe) {
        this.unsubscribe();
      }
      // Clear the timer if it exists.
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
    },
  };
